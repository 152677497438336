import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import packageJson from "../../package.json";

export default function Footer() {
	return (
		<Box id="#footer" sx={{ backgroundColor: grey[900], color: grey[50] }}>
			<Grid container spacing={4} sx={{ width: '80dvw', mx: 'auto', py: 4 }}>
				{/* <Grid item lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
					
				</Grid> */}
				<Grid item lg={6}>
					<img
						src="/assets/logo.png"
						style={{
							filter: 'drop-shadow(0 0 8px #fff)',
							borderRadius: '8px',
							marginBottom: '1rem',
						}}
						alt="ideabreed"
						height="100"
					/>
					<Typography variant="h5">
						Eagle Eye IT Service Pvt. Ltd.
					</Typography>

					<Typography fontSize={'12px'}>
						v{packageJson.version}
					</Typography>
					{/* <Typography variant="body1" sx={{ color: grey[400] }}>
						At Idea Breed, we have a passion for turning ideas into reality. Our
						world-class team is fluent in Ruby on Rails, React, React Native,
						iOS, Flutter, Android, and we’re ready to design, build, and train
						so your product is rock-solid and user-friendly.
					</Typography> */}
				</Grid>
				<Grid item lg={3}>
					<Typography variant="h6" mb={2}>
						USEFUL LINKS
					</Typography>
					{/* <Link href="#" sx={{ display: 'block', color: grey[300] }}>
						Home
					</Link>
					<Link
						href="https://ideabreed.com.np"
						sx={{ display: 'block', color: grey[300] }}
					>
						About Us
					</Link>
					<Link
						href="https://ideabreed.com.np/#services"
						sx={{ display: 'block', color: grey[300] }}
					>
						Services
					</Link>
					<Link
						href="https://ideabreed.com.np/our_products"
						sx={{ display: 'block', color: grey[300] }}
					>
						Our Products
					</Link> */}
				</Grid>
				<Grid item lg={3}>
					<Typography variant="h6" mb={2}>
						CONTACT US
					</Typography>
					<Typography variant="body1" sx={{ color: grey[300] }}>
						Nepalgunj, Banke
					</Typography>
					<Typography variant="body1" sx={{ color: grey[300] }}>
						Lumbini Province, Nepal
					</Typography>
					<Typography variant="body1" sx={{ color: grey[300] }}>
						Phone: 9816595000
					</Typography>


					{/* <Typography variant="body1" sx={{ color: grey[300] }}>
						Email: ideabreed@gmail.com
					</Typography>
					<Typography variant="body1" sx={{ color: grey[300], ml: 6 }}>
						info@ideabreed.net
					</Typography> */}
				</Grid>
			</Grid>
		</Box>
	);
}
